<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12" sm="12">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                        <b-row>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="fiscal_year_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('birtan.fiscal_year') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="cultivationPolicySetup.fiscal_year_id"
                                                            :options="fiscalYearList"
                                                            id="fiscal_year_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Method Cultivation" vid="method_cultivation_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="method_cultivation_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('bsri_config.cultivation_method_name') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="cultivationPolicySetup.method_cultivation_id"
                                                            :options="cultivationList"
                                                            id="method_cultivation_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Unit Name" vid="unit_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="unit_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('bsri_config.Measurement_unit') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="cultivationPolicySetup.unit_id"
                                                            :options="unitList"
                                                            id="unit_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Amount of subsidy per Acre" vid="per_acr_amount" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="per_acr_amount"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_config.amount_of_subsidy_per_acre')}} <span class="text-danger">*</span>
                                                    </template>
                                                        <b-form-input
                                                        type="number"
                                                        id="per_acr_amount"
                                                        v-model="cultivationPolicySetup.per_acr_amount"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                    &nbsp;
                                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { CultivationPolicySetupStore, CultivationPolicySetupUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getEditingData()
            this.cultivationPolicySetup = tmp
        }
    },
    data () {
        return {
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            cultivationPolicySetup: {
                fiscal_year_id: '0',
                method_cultivation_id: '0',
                crop_id: 1,
                unit_id: '0',
                per_acr_amount: ''
            },
            cultivationMethodList: []
        }
    },
    watch: {
    },
    mounted () {
        core.index()
        flatpickr('#datepicker', {})
    },
    computed: {
        fiscalYearList: function () {
            const dataList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
            return dataList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        cultivationList: function () {
            const data = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.filter(item => item.status === 1)
            return data.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        cropList: function () {
            const datalist = this.$store.state.incentiveGrant.commonObj.cropList.filter(item => item.status === 1)
            return datalist.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        unitList: function () {
            const datalist = this.$store.state.incentiveGrant.commonObj.measurementUnitsList.filter(item => item.status === 1)
            return datalist.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        }
    },
    methods: {
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${CultivationPolicySetupUpdate}/${this.id}`, this.cultivationPolicySetup)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, CultivationPolicySetupStore, this.cultivationPolicySetup)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
}
</script>
