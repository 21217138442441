
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('bsri_config.cultivation_policy_setup') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col xl="5" lg="5" md="5" sm="6" xs="8">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('globalTrans.fiscal_year')"
                            label-for="fiscal_year_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xl="5" lg="5" md="5" sm="6" xs="8">
                        <b-form-group
                            class="row"
                            label-cols-sm="6"
                            :label="$t('bsri_config.cultivation_method_name')"
                            label-for="method_cultivation_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.method_cultivation_id"
                                :options="cultivationList"
                                id="method_cultivation_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xl="2" lg="2" md="2" xs="4">
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('bsri_config.cultivation_policy_setup_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(per_acr_amount)="data">
                                            {{ $n(data.item.per_acr_amount) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" title="Edit" class="btn_table_action table_action_edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                                            <a href="javascript:" title="Status" class="btn_table_action" :class="data.item.status === 1 ? ' table_action_view' : ' table_action_toggle'" @click="remove(data.item)">
                                                <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { CultivationPolicySetupList, CultivationPolicySetupToggle } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      search: {
        fiscal_year_id: '0',
        method_cultivation_id: '0'
      },
      rows: [],
      subsidyList: [],
      item: ''
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
     fiscalYearList: function () {
          const dataList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
          return dataList.map(item => {
              if (this.$i18n.locale === 'bn') {
                  return { value: item.value, text: item.text_bn }
              } else {
                  return { value: item.value, text: item.text_en }
              }
          })
      },
      cultivationList: function () {
        const data = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.filter(item => item.status === 1)
           return data.map(item => {
              if (this.$i18n.locale === 'bn') {
                  return { value: item.value, text: item.text_bn }
              } else {
                  return { value: item.value, text: item.text_en }
              }
          })
      },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('bsri_config.cultivation_setup') + ' ' + this.$t('globalTrans.entry') : this.$t('bsri_config.cultivation_setup') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.fiscal_year'), class: 'text-center' },
          { label: this.$t('bsri_config.cultivation_method_name'), class: 'text-center' },
          { label: this.$t('bsri_config.crop_name'), class: 'text-center' },
          { label: this.$t('bsri_config.amount_of_subsidy_per_acre'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'cultivation_method_bn' },
          { key: 'crop_name_bn' },
          { key: 'per_acr_amount' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year' },
          { key: 'cultivation_method' },
          { key: 'crop_name' },
          { key: 'per_acr_amount' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    edit (item) {
      this.editItemId = item.id
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, CultivationPolicySetupToggle, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, CultivationPolicySetupList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getCustomDataList (data) {
        const listData = data.map(item => {
          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscal => fiscal.value === parseInt(item.fiscal_year_id))
            const fiscalYearData = {}
            if (typeof fiscalYearObj !== 'undefined') {
                fiscalYearData.fiscal_year = fiscalYearObj.text_en
                fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            } else {
                fiscalYearData.fiscal_year = ''
                fiscalYearData.fiscal_year_bn = ''
            }
            const cultivationYearObj = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.find(doc => doc.value === parseInt(item.method_cultivation_id))
            const cultivationYearData = {}
            if (typeof cultivationYearObj !== 'undefined') {
                cultivationYearData.cultivation_method = cultivationYearObj.text_en
                cultivationYearData.cultivation_method_bn = cultivationYearObj.text_bn
            } else {
                cultivationYearData.cultivation_method = ''
                cultivationYearData.cultivation_method_bn = ''
            }
            const cropListObj = this.$store.state.incentiveGrant.commonObj.cropList.find(crop => crop.value === parseInt(item.crop_id))
            const cropListData = {}
            if (typeof cropListObj !== 'undefined') {
                cropListData.crop_name = cropListObj.text_en
                cropListData.crop_name_bn = cropListObj.text_bn
            } else {
                cropListData.crop_name = ''
                cropListData.crop_name_bn = ''
            }
        return Object.assign({}, item, fiscalYearData, cultivationYearData, cropListData)
        })
        return listData
    }
  }
}
</script>
